import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";

export default function ClubsIndexPage({data}) {
  return (
    <Layout>
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url('/img/clubs.jpg')`,
        }}
      >
        <h1 className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen">
          Golf clubs
        </h1>
      </div>
      <section className="section">
        <div className="container">
          <div className="content">
            <BlogRoll posts={data.posts.edges} />
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`
  query BlogRollQuery {
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: {regex: "/(clubs)/" } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            featuredpost
            featuredimage {
              childImageSharp {
                gatsbyImageData(
                  width: 120
                  quality: 100
                  layout: FULL_WIDTH
                  aspectRatio: 1
                )
              }
            }
          }
        }
      }
    }
  }
`;